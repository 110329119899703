import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import SEO from "../components/SEO/index"
import HeroAbout from "../components/Sections/HeroAbout"
import StyledBackgroundAbout from "../components/BackgroundAbout"
import {
  Section,
  SectionTitle,
  SectionSubtitle,
} from "../components/CompoundComponents/Section"
import { Columns, Column } from "../components/CompoundComponents/Columns"
import {
  Card,
  CardContent,
  CardContentTitle,
  CardContentSubtitle,
} from "../components/CompoundComponents/Card"

import "./mystyles.scss"

const ChiefPage = ({ location }) => {
  return (
    <>
      <SEO title="Fanomed clinics" description="Fanomed clinics" />
      <StyledBackgroundAbout>
        <HeroAbout location={location} data="header.about.item-2" />
      </StyledBackgroundAbout>
      <Section medium white>
        <Columns centered>
          <Column is11 centered tcentered>
            <SectionTitle bottom>Наше руководство</SectionTitle>
            <SectionSubtitle bottom></SectionSubtitle>
          </Column>
          <Column is4>
            <Card mn>
              <CardContent>
                <CardContentTitle>
                  Назаров Нодирбек Назирджанович
                </CardContentTitle>
                <CardContentSubtitle>Главный врач</CardContentSubtitle>
              </CardContent>
            </Card>
          </Column>
          <Column is4>
            <Card mn>
              <CardContent>
                <CardContentTitle>Вахидова Саера Шукуровна</CardContentTitle>
                <CardContentSubtitle>Главная медсестра</CardContentSubtitle>
              </CardContent>
            </Card>
          </Column>
        </Columns>
      </Section>
    </>
  )
}

export default injectIntl(ChiefPage)
